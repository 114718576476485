<template>
    <v-snackbar v-if="show"
      v-model="show"
      :multi-line="multiline"
      :timeout="timeout"
      :color="getColor"
      location="top"
    >
    <div class="d-flex">
      <div class="align-self-center">
        <v-icon size="28">{{ getIcon }}</v-icon>
      </div>
      <div id="app-snack-text" class="align-self-center">
        <div>
          <strong>{{ getTitle }}</strong>
        </div>
        <div>
          <slot></slot>
        </div>
      </div>
      <div v-if="closable" class="align-self-center">
        <v-btn icon @click="show = false"  variant="text" dense>
          <v-icon size="20">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    </v-snackbar>

</template>

<script>
export default {
    name: 'BaseSnackbar',
    emits: ['close'],
    props: {
      timeout: {
        type: Number,
        required: false,
        default: -1 //en miliseg (-1 = infinito)
      },
      type: {
        type: String,
        required: true
      },
      closable: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        show: true,
        multiline: true,
        elevation: 12,
        alert:false,
        icon: 'mdi-information',
        styles: [
          { name: 'success',  color: 'success',  title: 'Success',      icon: 'mdi-checkbox-marked-circle' },
          { name: 'info',     color: 'info',     title: 'Information',  icon: 'mdi-information' },
          { name: 'warning',  color: 'warning',  title: 'Warning',      icon: 'mdi-alert' },
          { name: 'error',    color: 'error',    title: 'Error',        icon: 'mdi-alert-octagon' }
        ] 
      };
    },
    watch: {
      show(newState) {
        if(newState === false) {
          this.$emit('close', this.type);
        }
      }
    },
    computed: {
      getColor() {
        return this.styles.find(s => s.name === this.type).color;
      },
      getTitle() {
        return this.styles.find(s => s.name === this.type).title;
      },
      getIcon() {
        return this.styles.find(s => s.name === this.type).icon;
      }
    },
    // methods: {
    //   tryClose() {
    //     this.$emit('close');
    //     this.show = false;
    //   },
    // }
}
</script>

<style scoped>
#app-snack-text {
  width: 100%;
  padding-left: 0.8rem;
}

</style>
