<template>
  <base-snackbar
    v-if="!!error"
    type="error"
    :timeout="10000"
    :closable="true"
    @close="handleClose"
  >
    {{ error }}
  </base-snackbar>

  <base-snackbar
    v-if="!!okResponse"
    type="success"
    :timeout="10000"
    :closable="true"
    @close="handleClose"
  >
    {{ okResponse }}
  </base-snackbar>

  <v-app>
    <!-- <v-app-bar app color="black" :height="headerHeight"> -->
    <!-- <v-app-bar app color="rgb(110,110,110)" :height="headerHeight"> -->
    <v-app-bar app color="rgb(30,30,30)" flat :height="headerHeight">
      <div class="pl-1">
        <v-img
          class="mt-5 mb-2"
          contain
          :height="logoHeight"
          :width="logoWidth"
          :src="require('./assets/parseo.png')"
        ></v-img>
      </div>
      <!-- <v-img class="mt-5 mb-2" contain :height="logoHeight" :width="logoWidth" :src="require('./assets/parseo.png')"></v-img> -->
      <!-- <v-img contain class="mt-2" :height="130" :width="250" :src="require('./assets/parseo.png')"></v-img> -->
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="d-md-none"
      ></v-app-bar-nav-icon>
      <div>
        <v-toolbar color="transparent" class="mt-6">
          <!-- <v-btn color="#009FE3" class="mr-4" @click="scrollToSection('about-me')">About</v-btn>
            <v-btn color="#009FE3" class="mr-4" @click="scrollToSection('features')">Services</v-btn>
            <v-btn color="#009FE3" class="mr-9" @click="scrollToSection('contact')">Contact</v-btn> -->

          <v-row class="d-none d-md-flex">
            <v-btn
              variant="text"
              color="#009FE3"
              class="mr-4"
              @click="scrollToSection('about-me')"
              >About</v-btn
            >
            <v-btn
              variant="text"
              color="#009FE3"
              class="mr-4"
              @click="scrollToSection('features')"
              >Services</v-btn
            >
            <v-btn
              variant="text"
              color="#009FE3"
              class="mr-9"
              @click="scrollToSection('contact')"
              >Contact</v-btn
            >
          </v-row>
        </v-toolbar>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      location="right"
      temporary
      color="rgb(40,40,40)"
      class="d-md-none"
    >
      <v-list>
        <v-list-item
          base-color="#009FE3"
          @click="
            scrollToSection('about-me');
            drawer = false;
          "
        >
          <v-list-item-title>About</v-list-item-title>
        </v-list-item>
        <v-list-item
          base-color="#009FE3"
          @click="
            scrollToSection('features');
            drawer = false;
          "
        >
          <v-list-item-title>Services</v-list-item-title>
        </v-list-item>
        <v-list-item
          base-color="#009FE3"
          @click="
            scrollToSection('contact');
            drawer = false;
          "
        >
          <v-list-item-title>Contact</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <section id="hero">
        <v-row no-gutters>
          <v-carousel
            hide-delimiters
            :show-arrows="false"
            style="height: 100vh; width: 100vw"
          >
            <v-carousel-item>
              <v-img :src="require('./assets/banner.png')" cover height="100%">
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
                  width="90%"
                >
                  <div
                    class="d-flex flex-column justify-center align-center text-center"
                    style="height: 100%; max-width: 90%"
                  >
                    <div
                      class="text-h3 text-xs-h5 text-md-h4 text-lg-h3"
                      style="color: white; text-align: center"
                    >
                      Your vision, our expertise
                    </div>
                    <div
                      class="text-body-1 text-xs-body-2"
                      style="
                        color: white;
                        text-align: center;
                        word-break: break-word;
                        max-width: 100%;
                        margin-top: 10px;
                      "
                    >
                      Building innovative solutions, empowering your digital
                      future.
                    </div>
                  </div>
                </v-row>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-row>
      </section>

      <section id="about-me">
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 id="h2about" class="text-h3 font-weight-medium mb-3">ABOUT US</h2>

          <v-responsive class="mx-auto mb-8" width="56">
            <v-divider class="mb-1"></v-divider>
            <v-divider></v-divider>
          </v-responsive>

          <v-responsive
            class="mx-auto text-h6 font-weight-light mb-8"
            max-width="720"
          >
            At Parseo, we deliver tailored software solutions and IT consulting
            services that align with your business goals. With broad experience
            across industries, we combine technical expertise with a culture of
            innovation and collaboration. Our team continuously learns and
            adopts the latest technologies to provide cutting-edge solutions. We
            focus on building lasting partnerships based on trust, transparency,
            and exceeding expectations. When you choose Parseo, you gain a
            committed partner dedicated to your success.
          </v-responsive>
        </v-container>

        <div class="py-12"></div>
      </section>

      <section id="features" class="bg-grey-lighten-3">
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 id="h2features" class="text-h3 font-weight-medium mb-3">
            SERVICES
          </h2>

          <v-responsive class="mx-auto mb-12" width="56">
            <v-divider class="mb-1"></v-divider>
            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              v-for="({ icon, title, text }, i) in features"
              :key="i"
              cols="12"
              md="4"
            >
              <v-card class="py-12 px-4" color="grey-lighten-5" flat>
                <v-theme-provider>
                  <div>
                    <v-avatar color="#125fa1" size="88">
                      <v-icon size="36">{{ icon }}</v-icon>
                    </v-avatar>
                  </div>
                </v-theme-provider>

                <v-card-title
                  class="justify-center font-weight-black text-uppercase featureCardTitle"
                  v-text="title"
                ></v-card-title>

                <v-card-text
                  class="text-subtitle-1"
                  style="line-height: 28px; color: #00000099"
                  v-text="text"
                >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>

      <!-- <v-sheet id="contact" color="#1f6cae" tag="section" tile> -->
      <v-sheet id="contact" color="rgb(40,40,40)" tag="section" tile>
        <div class="py-12"></div>

        <v-container>
          <h2
            class="text-h3 font-weight-medium mb-3 text-uppercase text-center"
          >
            Contact Us
          </h2>

          <v-responsive class="mx-auto mb-12" width="56">
            <v-divider class="mb-1"></v-divider>
            <v-divider></v-divider>
          </v-responsive>

          <v-theme-provider>
            <v-form ref="contactForm" v-model="isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Name*"
                    v-model.trim="formFields.name.val"
                    :rules="formFields.name.rules"
                    flat
                    solo
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Email*"
                    v-model.trim="formFields.email.val"
                    :rules="formFields.email.rules"
                    flat
                    solo
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Subject*"
                    v-model.trim="formFields.subject.val"
                    :rules="formFields.subject.rules"
                    flat
                    solo
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    label="Message*"
                    v-model.trim="formFields.message.val"
                    :rules="formFields.message.rules"
                    flat
                    solo
                  ></v-textarea>
                </v-col>

                <v-col class="mx-auto" cols="auto">
                  <v-btn
                    color="#009FE3"
                    :loading="isLoading"
                    @click="sendContactData"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-theme-provider>
        </v-container>

        <div class="py-12"></div>
      </v-sheet>
    </v-main>

    <!-- <v-footer class="justify-center" color="#125fa1" height="100"> -->
    <v-footer class="justify-center" color="rgb(30,30,30)" height="100">
      <div
        class="text-h6 font-weight-light grey--text text--lighten-1 text-center"
      >
        &copy; {{ new Date().getFullYear() }} — parseo.com.uy
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "App",

  data() {
    return {
      drawer: false,
      isFormValid: false,
      isLoading: false,
      error: null,
      okResponse: null,
      formFields: {
        name: {
          val: null,
          rules: [(v) => !!v || "Name is required"]
        },
        email: {
          val: null,
          rules: [
            v => !!v || "Email is required",
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) && (v && v.length <= 256) || 'Invalid Email format'
          ]
        },
        subject: {
          val: null,
          rules: [(v) => !!v || "Subject is required"]
        },
        message: {
          val: null,
          rules: [(v) => !!v || "Message is required"]
        }
      },
      features: [
        {
          icon: "mdi-finance",
          title: "SCALE & AUGMENTATION",
          text: "Our Scale & Augmentation services are designed to seamlessly integrate with your existing team, providing the specialized talent and expertise you need to scale your operations. Whether you’re facing a tight deadline, require additional skills, or need to quickly expand your development capacity, Parseo offers flexible and efficient solutions to help you meet your business objectives without compromising on quality."
        },
        {
          icon: "mdi-lightbulb-on-outline",
          title: `Product Discovery`,
          text: "Our Product Discovery service is focused on transforming your ideas into a validated Minimum Viable Product (MVP) quickly and efficiently. At Parseo, we help you navigate the early stages of product development, from understanding market needs to defining key features and conducting rapid iterations. Our goal is to reduce risk and maximize value by validating concepts early on, ensuring your product is well-aligned with user expectations and ready for scaling. With our expertise, you can confidently move forward with a product strategy that’s backed by real insights."
        },
        {
          icon: "mdi-devices",
          title: "PRODUCT DEVELOPMENT",
          text: "From ideation to launch, we specializes in full-cycle product development. We work closely with our clients to transform innovative ideas into fully realized products that resonate with users and drive growth. Our multidisciplinary team covers all aspects, including UI/UX design, development, testing, and scaling. With a strong focus on quality and adaptability, we deliver products that are not only functional but also aligned with your long-term business strategy."
        }
      ]
    };
  },

  computed: {
    headerHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "80";
        case "sm":
          return "100";
        case "md":
          return "100";
        case "lg":
          return "100";
        case "xl":
          return "100";
        default:
          return "100";
      }
    },
    logoHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "100";
        case "sm":
          return "130";
        case "md":
          return "130";
        case "lg":
          return "130";
        case "xl":
          return "130";
        default:
          return "130";
        // case 'xs': return '70'
        // case 'sm': return '90'
        // case 'md': return '90'
        // case 'lg': return '90'
        // case 'xl': return '90'
        // default: return '90'
      }
    },
    logoWidth() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "200";
        case "sm":
          return "250";
        case "md":
          return "250";
        case "lg":
          return "250";
        case "xl":
          return "250";
        default:
          return "250";
        // case 'xs': return '157'
        // case 'sm': return '202'
        // case 'md': return '202'
        // case 'lg': return '202'
        // case 'xl': return '202'
        // default: return '202'
      }
    }
  },
  methods: {
    clearFormFields() {
      this.formFields.name.val = null;
      this.formFields.email.val = null;
      this.formFields.subject.val = null;
      this.formFields.message.val = null;
    },
    async sendContactData() {
      await this.$refs.contactForm.validate();

      if (this.isFormValid) {
        this.isLoading = true;

        try {
          const contactData = {
            name: this.formFields.name.val,
            email: this.formFields.email.val,
            subject: this.formFields.subject.val,
            message: this.formFields.message.val
          };

          const response = await fetch(
            "https://p2mzq2zqx4.execute-api.sa-east-1.amazonaws.com/send-contact-email",
            {
              method: "POST",
              // mode: 'no-cors',
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify(contactData)
            }
          );

          const responseData = await response.json();

          if (response.ok) {
            this.clearFormFields();
            this.okResponse =
              "Thank you for getting in touch! Our team will review your request and get back to you as soon as possible.";
          } else {
            console.log(response);
            console.log(responseData);
            //Unable to send contact message, please try again later!
            this.error =
              "Oops! Something went wrong and we couldn't send your message. Please try again later. We apologize for the inconvenience.";
          }
        } catch (err) {
          //Unable to send contact message, please try again later!
          this.error =
            "Oops! Something went wrong and we couldn't send your message. Please try again later. We apologize for the inconvenience.";
        } finally {
          this.isLoading = false;
        }
      }
    },
    handleClose(theType) {
      if (theType === "error") this.error = null;
      if (theType === "success") this.okResponse = null;
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  },
  watch: {}
};
</script>

<style scoped>
#h2features {
  color: #125fa1;
}
#h2about {
  color: #125fa1;
}
.featureCardTitle {
  color: #125fa1;
  word-break: break-word;
  white-space: pre-wrap;
}

.my-text-style :deep(.v-text-field__slot) input {
  color: #5fc5f0;
  font-size: larger;
  font-weight: bold;
}

.my-text-style :deep(.v-text-field__slot) input::placeholder {
  color: #5fc5f0 !important;
  font-size: medium;
  font-weight: normal;
}

:deep(.v-toolbar__content) {
  padding: 4px 8px !important;
}

/* .blurred-image {
  -webkit-backdrop-filter: blur(5px); 
  backdrop-filter: blur(5px);  
  filter: blur(5px); 
} */

.my-span {
  position: absolute;
  /* background-color: #009FE3; */
  /* background: rgba(0, 159,227, 0); */
  /* color: white; */
  color: rgba(255, 255, 255, 0.7);
  font-size: 25px;
  font-weight: bold;
  /* margin-right: 0; */
  /* float: right; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* padding: 15px; */
  /* border-radius: 20px; */
}
</style>
