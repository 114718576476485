import { createApp } from 'vue'
import App from './App.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import BaseSnackbar from './components/ui/BaseSnackbar.vue'

const vuetify = createVuetify({
  components,
  directives,
})

//createApp(App).mount('#app')
// createApp(App).use(vuetify).mount('#app')
const app = createApp(App)

app.use(vuetify)

app.component('base-snackbar', BaseSnackbar);

app.mount('#app')